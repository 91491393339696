export const headerLabels = {
  en: {
    nav: {
      home: "Home",
      aboutUs: "About us",
      ourServices: "Our services",
      partners: "Partners",
      contacts: "Contacts",
    },
  },
  ua: {
    nav: {
      home: "Головна",
      aboutUs: "Про нас",
      ourServices: "Рішення",
      partners: "Партнери",
      contacts: "Контакти",
    },
  },
};
