import React from "react";
import { Element } from "react-scroll";
import { contactsHash } from "../../router";
import styles from "./styles.module.css";

function ContactsSection(props) {
  const { contacts } = props;
  const { title, data } = contacts;

  return (
    <Element name={contactsHash}>
      <div className={styles.footerWrapper}>
        <h2>{title}: </h2>
        <div className={styles.itemsContainer}>
          {data.map((el, i) => (
            <div className={styles.item} key={+i}>
              <div className={styles.itemTitle}>{el.label}:</div>
              <div className={styles.itemValue}>{el.value}</div>
            </div>
          ))}
        </div>
      </div>
    </Element>
  );
}

export default ContactsSection;
