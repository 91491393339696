import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.css";

function LinkButton(props) {
  const { label, path, ...rest } = props;

  return (
    <NavLink exact {...rest} className={styles.button} to={path}>
      {label}
    </NavLink>
  );
}

export default LinkButton;
