import React from "react";
import styles from "./styles.module.css";

function PageNotFound() {
  return (
    <div className={styles.notFound}>
      <div>
        <div className={styles.code}>404</div>
        <div className={styles.text}>Сторінку не знайдено!..</div>
        <div className={styles.smile}>:(</div>
      </div>
    </div>
  );
}

export default PageNotFound;
