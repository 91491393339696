import apc from "./../../../../img/partners-logo/apc.png";
import check_point from "./../../../../img/partners-logo/check_point.png";
import elk from "./../../../../img/partners-logo/elk.png";
import freebsd from "./../../../../img/partners-logo/freebsd.png";
import hetzner from "./../../../../img/partners-logo/hetzner.png";
import intel from "./../../../../img/partners-logo/intel.png";
import kerio from "./../../../../img/partners-logo/kerio.png";
import linux from "./../../../../img/partners-logo/linux.png";
import mcafee from "./../../../../img/partners-logo/mcafee.png";
import microsoft from "./../../../../img/partners-logo/microsoft.png";
import mikrotik from "./../../../../img/partners-logo/mikrotik.png";
import openVPN from "./../../../../img/partners-logo/openVPN.png";
import pfSense from "./../../../../img/partners-logo/pfSense.png";
import vmware from "./../../../../img/partners-logo/vmware.png";

// labels
import { homeLabels } from "./../../../../labels/home/index";
import { ourServicesLabels } from "../../../../labels/ourServices/index";

// routes
import { SERVICE_ITEM_PARAM_PATTERN, SERVICE_ITEMS, ROUTES } from "../../../../router";

// home labels
const { title, services, partners, contacts } = homeLabels.en;

// ourServices labels
const {
  cloudDesktop,
  managedItService,
  disasterRecovery,
  networkSolution,
  supportConsulting,
  securityServices,
} = ourServicesLabels.en.services;

const config = {
  title,
  services: {
    title: services.title,
    blocks: [
      {
        ...cloudDesktop,
        linkLabel: services.ditaileLinkLabel,
        linkPath: `${ROUTES.OUR_SERVICES.replace(
          SERVICE_ITEM_PARAM_PATTERN,
          SERVICE_ITEMS.cloudDesktop
        )}`,
      },
      {
        ...managedItService,
        linkLabel: services.ditaileLinkLabel,
        linkPath: `${ROUTES.OUR_SERVICES.replace(
          SERVICE_ITEM_PARAM_PATTERN,
          SERVICE_ITEMS.managedItService
        )}`,
      },
      {
        ...disasterRecovery,
        linkLabel: services.ditaileLinkLabel,
        linkPath: `${ROUTES.OUR_SERVICES.replace(
          SERVICE_ITEM_PARAM_PATTERN,
          SERVICE_ITEMS.disasterRecovery
        )}`,
      },
      {
        ...networkSolution,
        linkLabel: services.ditaileLinkLabel,
        linkPath: `${ROUTES.OUR_SERVICES.replace(
          SERVICE_ITEM_PARAM_PATTERN,
          SERVICE_ITEMS.networkSolution
        )}`,
      },
      {
        ...supportConsulting,
        linkLabel: services.ditaileLinkLabel,
        linkPath: `${ROUTES.OUR_SERVICES.replace(
          SERVICE_ITEM_PARAM_PATTERN,
          SERVICE_ITEMS.supportConsulting
        )}`,
      },
      {
        ...securityServices,
        linkLabel: services.ditaileLinkLabel,
        linkPath: `${ROUTES.OUR_SERVICES.replace(
          SERVICE_ITEM_PARAM_PATTERN,
          SERVICE_ITEMS.securityServices
        )}`,
      },
    ],
  },
  partners: {
    title: partners.title,
    data: [
      { logo: apc, title: partners.iconTitles.apc },
      { logo: check_point, title: partners.iconTitles.check_point },
      { logo: elk, title: partners.iconTitles.elk },
      { logo: freebsd, title: partners.iconTitles.freebsd },
      { logo: hetzner, title: partners.iconTitles.hetzner },
      { logo: intel, title: partners.iconTitles.intel },
      { logo: kerio, title: partners.iconTitles.kerio },
      { logo: linux, title: partners.iconTitles.linux },
      { logo: mcafee, title: partners.iconTitles.mcafee },
      { logo: microsoft, title: partners.iconTitles.microsoft },
      { logo: mikrotik, title: partners.iconTitles.mikrotik },
      { logo: openVPN, title: partners.iconTitles.openVPN },
      { logo: pfSense, title: partners.iconTitles.pfSense },
      { logo: vmware, title: partners.iconTitles.vmware },
    ],
  },
  contacts,
};
export default config;
