import React from "react";
import Header from "../Header";
import classNamesFunction from "classnames";
import styles from "./styles.module.css";

const getClassNames = (props) => {
  const { className } = props;
  return classNamesFunction(styles.pageWrapper, className);
};

function ViewLayout(props) {
  const { children,childrenWrapperClassName } = props;

  return (
    <div className={getClassNames(props)}>
      <Header />
      <div className={classNamesFunction(styles.childrenWrapper, childrenWrapperClassName)}>{children}</div>
    </div>
  );
}

export default ViewLayout;
