import { useState, createContext, useContext } from "react";

const localStorageLangKey = "LANGUAGE_KEY";

export const languagesKeys = {
  en: "EN",
  ua: "UA",
};

// TODO: мови англ укр
const languagesOptions = {
  [languagesKeys.en]: {
    // [languagesKeys.en]: {
    //   key: languagesKeys.en,
    //   title: "English",
    // },
    [languagesKeys.ua]: {
      key: languagesKeys.ua,
      title: "Ukrainian",
    },
  },
  [languagesKeys.ua]: {
    // [languagesKeys.en]: {
    //   key: "АНГЛ",
    //   title: "Англійська",
    // },
    [languagesKeys.ua]: {
      key: "УКР",
      title: "Українська",
    },
  },
};

const LanguagesContext = createContext(languagesKeys.ua);

export const useLanguagesProvider = () => {
  const [currentLanguageKey, setCurrentLanguageKey] = useState(
    localStorage.getItem(localStorageLangKey) || languagesKeys.ua
  );

  const handleLanguageChange = (newKey) => {
    setCurrentLanguageKey(newKey);
    localStorage.setItem(localStorageLangKey, newKey);
  };

  return {
    Provider: LanguagesContext.Provider,
    state: {
      languagesKeys,
      languagesOptions: languagesOptions[currentLanguageKey],
      currentLanguageKey,
      setCurrentLanguageKey: handleLanguageChange,
    },
  };
};

export default useLanguagesProvider;

export const useLanguagesConsumer = () => {
  return useContext(LanguagesContext);
};
