import React from "react";
import { useConfig } from "./hooks";
import ViewLayout from "../../components/ViewLayout";
import AboutUsSection from "./components/AboutUsSection/AboutUsSection";

function AboutUs() {
  const config = useConfig();

  return (
    <ViewLayout>
      <AboutUsSection title={config.title} items={config.blocks} />
    </ViewLayout>
  );
}

export default AboutUs;
