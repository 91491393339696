import React from "react";
import { Fade } from "react-reveal";
import classNamesFunction from "classnames";
import styles from "./styles.module.css";

function TitleBlock(props) {
  const { labels, buttons, className } = props;
  const { title, description } = labels;

  const Description = () =>
    description ? (
      <>
        <span />
        <p>{description}</p>
      </>
    ) : null;

  const Buttons = () =>
    buttons ? (
      <div>
        <button>test</button>
      </div>
    ) : null;

  return (
    <div className={classNamesFunction([styles.titleBlockWrapper, className])}>
      <Fade right>
        <figure />
      </Fade>
      <Fade left>
        <section>
          <div>
            <h2>{title}</h2>
            <Description />
          </div>
          <Buttons />
        </section>
      </Fade>
    </div>
  );
}

export default TitleBlock;
