import { headerLabels } from "./../../../../labels/header/index";

const { home, aboutUs, ourServices, partners, contacts } = headerLabels.en.nav;

const config = {
  nav: {
    home,
    aboutUs,
    ourServices,
    partners,
    contacts,
  },
};
export default config;
