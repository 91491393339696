import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import OurServices from "./pages/OurServices/";
import PageNotFound from "./pages/404";
import { ROUTES } from "./router";
import AboutUs from "./pages/AboutUs";
import useLanguagesProvider from "./languagesContext";

const { HOME, ABOUT_US, OUR_SERVICES, PAGE_NOT_FOUND } = ROUTES;

function App() {
  const { Provider, state: languageState } = useLanguagesProvider();

  return (
    <Provider
      value={{
        currentLanguageKey: languageState.currentLanguageKey,
        settings: {
          ...languageState,
        },
      }}
    >
      <BrowserRouter>
        <Switch>
          <Route exact path={HOME} component={Home} />
          <Route exact path={ABOUT_US} component={AboutUs} />

          <Route exact path={OUR_SERVICES} component={OurServices} />
          <Route exact path={PAGE_NOT_FOUND} component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
