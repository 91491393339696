import { useEffect, useState } from "react";

export const useMobileNavLogic = () => {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflowX = "hidden";
    body.style.overflowY = openMenu ? "hidden" : "auto";
  }, [openMenu]);

  return { openMenu, setOpenMenu };
};
