import { useLanguagesConsumer } from "../../../../languagesContext";
import en from "./en";
import ua from "./ua";

const useConfig = () => {
  const { currentLanguageKey, settings } = useLanguagesConsumer();
  if (currentLanguageKey === settings.languagesKeys.en) return en;
  if (currentLanguageKey === settings.languagesKeys.ua) return ua;
};

export default useConfig;
