import React from "react";
import { Fade } from "react-reveal";
import TitleBlock from "../../../../components/TitleBlock";

import styles from "./styles.module.css";

const InfoContainer = (props) => {
  const { title, description } = props;
  return (
    <Fade cascade>
      <div className={styles.infoContainer}>
        <h3>{title}</h3>        
        <p>{description.map((el, i) => <li key={+i}>{el}</li>)}</p>
      </div>
    </Fade>
  );
};

function AboutUsSection(props) {
  const { title, items } = props;
  return (
    <>
      <TitleBlock className={styles.titleBlockWrapper} labels={{ title, items }} />

      {items.map((item, idx) => (
        <InfoContainer key={idx} {...item} />
      ))}
    </>
  );
}

export default AboutUsSection;
