export const SERVICE_ITEMS = {
  managedItService: "managed-it-service",
  disasterRecovery: "disaster-recovery",
  cloudDesktop: "cloud-desktop",
  networkSolution: "network-solutions",
  supportConsulting: "support-consulting",
  securityServices: "security-services",
};

export const solutionsHash = "#solutions";
export const partnersHash = "#partners";
export const contactsHash = "#contacts";

export const SERVICE_ITEM_PARAM_PATTERN = ":serviceItem";
export const ROUTES = {
  HOME: "/",
  ABOUT_US: "/about-us",
  OUR_SERVICES: `/our-services/${SERVICE_ITEM_PARAM_PATTERN}`,
  PAGE_NOT_FOUND: "*",
};
