import React from "react";
import MobileNav from "../MobileNav";
import { useLanguagesConsumer } from "../../languagesContext";

import styles from "./styles.module.css";

function Navigation(props) {
  const { buttons } = props;

  const { currentLanguageKey, settings } = useLanguagesConsumer();

  const renderLanguageButton = (languageKey, idx) => {
    const keyToDisplay = settings.languagesOptions[languageKey].key;
    return (
      <button
        className={styles.languageBtn}
        key={keyToDisplay + idx}
        onClick={() => settings.setCurrentLanguageKey(languageKey)}
      >
        <abbr className={styles.langAbbr} title={settings.languagesOptions[languageKey].title}>
          {currentLanguageKey === languageKey ? <b>{keyToDisplay}</b> : keyToDisplay}
        </abbr>
      </button>
    );
  };

  return (
    <>
      <MobileNav buttons={buttons} />

      <div className={styles.desktopMenuWrapper}>
        {buttons.map((button, i) => {
          const { component: Button, ...rest } = button;
          return <Button key={+i} {...rest} />;
        })}
        <div className={styles.languagesWrapper}>
          {Object.keys(settings.languagesOptions).map(renderLanguageButton)}
        </div>
      </div>
    </>
  );
}

export default Navigation;
