import { useEffect } from "react";
import { animateScroll } from "react-scroll";
import { useParams } from "react-router";

const scrollerConfig = {
  duration: 700,
  delay: 100,
  smooth: true,
};

export const useOurServicesLogic = () => {
  const { serviceItem } = useParams();

  useEffect(() => animateScroll.scrollToTop(scrollerConfig), []);

  return { serviceItem };
};
