import { ourServicesLabels } from "../../../../labels/ourServices/index";

import { SERVICE_ITEMS } from "../../../../router";

const { backLinkLabel, services, contacts } = ourServicesLabels.en;
const {
  cloudDesktop,
  managedItService,
  disasterRecovery,
  networkSolution,
  supportConsulting,
  securityServices,
} = services;

const config = {
  backLinkLabel,
  services: {
    [SERVICE_ITEMS.cloudDesktop]: {
      title: cloudDesktop.title,
      description: cloudDesktop.description,
    },
    [SERVICE_ITEMS.managedItService]: {
      title: managedItService.title,
      description: managedItService.description,
    },
    [SERVICE_ITEMS.disasterRecovery]: {
      title: disasterRecovery.title,
      description: disasterRecovery.description,
    },
    [SERVICE_ITEMS.networkSolution]: {
      title: networkSolution.title,
      description: networkSolution.description,
    },
    [SERVICE_ITEMS.supportConsulting]: {
      title: supportConsulting.title,
      description: supportConsulting.description,
    },
    [SERVICE_ITEMS.securityServices]: {
      title: securityServices.title,
      description: securityServices.description,
    },
  },
  contacts
};
export default config;
