import React from "react";
import { Element } from "react-scroll";
import classNamesFunction from "classnames";
import InfoBlocksList from "../../../../components/InfoBlocksList";

import styles from "./styles.module.css";
import { solutionsHash } from "../../../../router";

const getClassNames = (props) => {
  const { className } = props;
  return classNamesFunction(styles.servicesSection, className);
};

function ServicesSection(props) {
  const { services } = props;
  const { title, blocks } = services;

  return (
    <Element name={solutionsHash}>
      <div className={getClassNames(props)}>
        <div className={styles.bg} />
        <h2 className={styles.title}>{title}</h2>
        <InfoBlocksList items={blocks} />
      </div>
    </Element>
  );
}

export default ServicesSection;
