import {homeLabels} from "../home"

const {en, ua} = homeLabels 


export const ourServicesLabels = {
  en: {
    backLinkLabel: "< Back",
    services: {
      cloudDesktop: {
        title: "Cloud Desktop",
        description: [`1I'm a paragraph. Click here to add your own text and edit me. It’s easy.
           Just click “Edit Text” or double click me to add your own content and make changes to the font.`,
           " ↓↓↓ ",
           "To call us"],
      },
      managedItService: {
        title: "Managed It Service",
        description: [`2I'm a paragraph. Click here to add your own text and edit me. It’s easy.
           Just click “Edit Text” or double click me to add your own content and make changes to the font.`,
           " ↓↓↓ ",
           "To call us"],
      },
      disasterRecovery: {
        title: "Disaster Recovery",
        description: [`3I'm a paragraph. Click here to add your own text and edit me. It’s easy.
           Just click “Edit Text” or double click me to add your own content and make changes to the font.`,
           " ↓↓↓ ",
           "To call us"],
      },
      networkSolution: {
        title: "Network Solution",
        description: [`4I'm a paragraph. Click here to add your own text and edit me. It’s easy.
           Just click “Edit Text” or double click me to add your own content and make changes to the font.`,
           " ↓↓↓ ",
           "To call us"],
      },
      supportConsulting: {
        title: "Support Consulting",
        description: [`5I'm a paragraph. Click here to add your own text and edit me. It’s easy.
        Just click “Edit Text” or double click me to add your own content and make changes to the font.`,
        " ↓↓↓ ",
        "To call us"],
      },
      securityServices: {
        title: "Security Services",
        description: [`6I'm a paragraph. Click here to add your own text and edit me. It’s easy.
           Just click “Edit Text” or double click me to add your own content and make changes to the font.`,
           " ↓↓↓ ",
           "To call us"],
      },
    },
    contacts:en.contacts
  },
  ua: {
    backLinkLabel: "< Повернутись назад",
    services: {
      cloudDesktop: {
        title: "Хмарні рішення",
        description: [`Хмарні технології є одним з головних напрямків розвитку в ІТ. Мова йде про надання користувачу ресурсів та потужностей
         у вигляді інтернет-сервісу. Актуальність хмарних обчислень пов’язана зі зниженням витрат, масштабованістю і гнучкістю архітектури моделі,
          а також тим, що "хмари" є зручним інструментом для компаній, які вирішили оптимізувати свої ресурси. Перехід від локальної інфраструктури
           до хмари значно зменшує капітальні витрати та витрати на адміністрування. Ми створимо для вас хмарну ІТ інфраструктуру та забезпечимо
           захист та резервування ваших даних в ній.`,
           " ↓↓↓ ",
           "Щоб замовити безкоштовну консультацію зателефонуйте  або напишіть нам "],
      },
      managedItService: {
        title: "Корпоративна інтеграція",
        description: [`Побудова корпоративної системи, що забезпечує безперебійне та ефективне функціонування бізнесу, центрів обробки даних і розподілених інформаційних інфраструктур,
         є першочерговим завданням для підприємства. Ми пропонуємо Вам всі стадії інжинірингових послуг: від проектування і комплектної поставки устаткування до монтажу інженерних систем 
         «під ключ». Створення універсальної корпоративної системи забезпечує керованість бізнесу та оперативність адаптації до його мінливих умов. Ця діяльність безпосередньо пов'язана
          із встановленням та налагодженням операційних систем, баз даних, офісних застосунків, засобів зв'язку, структурованих кабельних систем і активних мережевих пристроїв, пристроїв
           зберігання даних, підключення до Інтернету, контролю доступу, живлення, тощо.`,
           " ↓↓↓ ",
           `Щоб замовити безкоштовну консультацію зателефонуйте  або напишіть нам `],
      },
      disasterRecovery: {
        title: "Корпоративна безпека",
        description: [`Розробка і створення системи управління інформаційною безпекою є важливим  і надзвичайно актуальним питанням для кожної компанії, яка дбає про свій захист.
         STC допоможе Вам створити таку систему, яка є сукупністю організаційних та інженерно-технічних заходів, що спрямовані на забезпечення захисту інформації 
          від розголошення, витоку і несанкціонованого доступу.`,
          "\u2022  Захист мережевої інфраструктури.",
          "\u2022	 Сегментація і мікросегментація мережі.",
          `Найбільш популярний інструмент зниження ризиків кібербезпеки, - це мікро- і цільова сегментація мережі з різними рівнями довіри і високопродуктивною розширеною безпекою.
          Можна інтелектуально сегментувати мережеві та інфраструктурні активи незалежно від їх місця розташування, будь то локально або в декількох хмарах. Встановлюється динамічний і детальний контроль доступу шляхом постійного моніторингу рівня довіри та відповідної адаптації політики безпеки. Високопродуктивна, вдосконалена система безпеки ізолює критично важливі ІТ-ресурси, забезпечуючи швидке виявлення і запобігання загрозам з використанням систем сучасної аналітики і автоматизації.
          Заснована на фізичних і віртуальних передових технологіях сегментація на основі намірів, забезпечує наскрізну сегментацію, яка розширює мережі і географічні кордони.`, 
          "\u2022	 Next Generation Firewall (NGFW).",
          `Традиційні міжмережеві екрани і точкові рішення кібербезпеки не володіють тими особливостями і можливостями, які необхідні сьогодні для надійного захисту організацій від нових, складних загроз. Потрібно врахувати, що будь-який інцидент порушення безпеки може призвести до простоїв, втраченими можливостями та втрати довіри до вашого бізнесу.
          Ми пропонуємо захист корпоративних мереж з можливістю гнучкого та швидкого розгортання в будь-якій точці мережі – від філій до головного офісу, від центру обробки даних до внутрішніх сегментів і хмарних рішень. Міжмережеві екрани нового покоління забезпечують автоматичне відстеження подій безпеки хмарних додатків, пристроїв IoT, а також можуть бути використані в якості централізованої системи моніторингу. Слід відзначити, що впровадження даного рішення працює без збитку для продуктивності мережі підприємства.
          `,
          "\u2022	 Unified threat management (UTM).",
          `Обмежений бюджет на вирішення завдань, пов’язаних із захистом ІТ-активів компанії – часта проблема різних компаній. Наявність декількох окремих пристроїв, кожний з яких призначений для виконання спеціалізованих функцій, таких як фільтрація спаму або антивірусний захист, не полегшує це завдання, збільшуючи вартість і складність управління.
          Розвиток інтернету речей і використання публічних хмар розмивають традиційний периметр мережі і відкривають для хакерів можливості створення нових стратегій злому. Дані тенденції змушують застосовувати більш складні і дорогі комплексні рішення кіберзахисту.
          Для боротьби з цими проблемами безпеки ми пропонуємо впровадити уніфіковане управління загрозами (Unified threat management або UTM) в комплексі з екраном нового покоління - NGFW. Мета таких UTM-систем – надання максимально повного набору функцій і утиліт безпеки (антивірус, антиспам, фільтрація контенту і веб-фільтрація) в одному пристрої для максимального перекриття можливих векторів і способів зовнішніх атак та внутрішніх.
          `,
          "\u2022	 Інспекція зашифрованого трафіку.",
          `Постійно зростаючий обсяг HTTPS трафіку створює нові загрози і відкриває нові можливості для витоку конфіденційної інформації. Всі ці загрози можуть опинитися поза зоною видимості пристроїв безпеки у зв’язку з використанням шифрування за допомогою SSL протоколу.
          Включення функції інспекції зашифрованого трафіку (DPI) дозволяють проводити аналіз зашифрованого SSL трафіку на наявність загроз, що радикально підвищує захищеність ІТ-активів.
          SSL інспекція проводиться, як правило, пристроями NGFW / UTM на кордоні периметра мережі, що захищається. Перевірка здійснюється відповідно заданих правил, що визначають тип аналізованого SSL трафіку і критерії його безпеки. Аналіз виконується з використанням програмних компонентів UTM, що підтримують інспекцію зашифрованого трафіку, а саме: контроль додатків, URL фільтрацію, IPS, DLP, антивірус і антіботнет.
          `,
          "Захист від витоку конфіденційної інформації.",
          `Для кожного підприємства гостро поставлено питання захисту від витоку цінної інформації, який може статися або внаслідок цілеспрямованої атаки, або з необережності співробітників. Однак, як показує статистика, понад 80% інцидентів, пов’язаних з розкраданням даних, викликані внутрішніми подіями, джерелами яких стали легальні користувачі компанії, в той час коли більшість традиційних засобів безпеки спрямовані тільки на захист від загроз ззовні.
          Для усунення загрози розкрадання цінної інформації ми пропонуємо застосувати систему DLP (Data Loss / Leakage Prevention). Це апаратно-програмні технології, які контролюють найбільш ймовірні канали витоку і ідентифікують дані за ступенем конфіденційності.
          Це дозволяє здійснювати:
          `,
          "\u2022	 моніторинг та блокування вхідних і вихідних повідомлень співробітників",
          "\u2022	 моніторинг та блокування відправки файлів на зовнішні носії",
          "\u2022	 моніторинг та контроль мережевих сховищ інформації та веб-ресурсів",
          "\u2022	 контроль голосових і текстових повідомлень, переданих по протоколу SIP",
          "Впровадження рішень двофакторної аутентифікації.",
          `Через широке поширення шкідливих програм і фішингових сайтів, які легко можуть перехопити складні паролі, система однофакторной парольної аутентифікації не здатна забезпечити безпечний доступ до корпоративних ресурсів. З метою додаткового захисту необхідно використовувати рішення двофакторної аутентифікації для підтвердження особи користувачів.
          Ми пропонуємо сучасні системи двофакторної аутентифікації, які нівелюють недоліки класичної парольної системи. Першим чинником такої системи залишається логін і пароль, а другий – це одноразовий обмежений терміном дії код доступу у вигляді смарт-повідомлення, який генерується випадковим чином і який неможливо перехопити. Це дозволяє надійно захистити дані від зловмисників.
          `,
          `Переваги:`,
          `\u2022  інтуїтивно зрозумілі, централізовані служби аутентифікації і авторизації`,
          `\u2022  двохфакторна перевірка справжності та управління через токени`,
          `\u2022  єдиний вхід (SSO) для веб-ресурсів / хмарних і мережевих ресурсів`,
          `\u2022  управління гостьовим доступом, BYOD і сертифікатами`,
          `\u2022  зручність розгортання і ліцензування`,
          `\u2022  інтеграція з безпечними каталогами`,
          `Об'єднання локальних мереж і віддалений доступ`,
          `Підтримка єдиної прозорої політики безпеки і відповідного контролю доступу користувачів, програмного та мережевого забезпечення, незалежно від їх місця розташування, в корпоративному середовищі є важливим аспектом інформаційного захисту.
          Ми розробляємо рішення з побудови безпечних мереж будь-якої складності. Враховуємо принципи взаємної аутентифікації і конфіденційності для забезпечення безпеки даних і бізнес-процесів в незахищених мережах.`,
          `Переваги:`,
          `\u2022  різноманітні VPN на основі IPsec або SSL`,
          `\u2022  єдине управління для ефективного контролю крипто-VPN, маршрутизації і міжмережевих екранів`,
          `\u2022  апаратна підтримка шифрування`,
          `\u2022  комплексна безпека передачі даних: «додаток-додаток», «користувач-користувач», «користувач-машина», «машина-машина»`,  
          " ↓↓↓ ",
          "Щоб замовити безкоштовну консультацію з питань інформаційної та кібербезпеки зателефонуйте  або напишіть нам"],
      },
      networkSolution: {
        title: "Мережеві рішення",
        description: [`Локальна обчислювальна мережа є основною частиною корпоративної мережі, що забезпечує функціонування і взаємодію різних розподілених додатків, що входять до складу IT інфраструктури
         підприємства. Від того, наскільки правильно спроектована та змонтована локальна мережа залежить стабільність роботи інформаційних систем, а, отже, і стабільність усього бізнесу.STC пропонує 
         розробку і впровадження стабільно працюючих локальних мереж з урахуванням всіх індивідуальних потреб вашого бізнесу.
         Побудова локальної мережі необхідна для створення єдиного мережевого простору підприємства (організації), призначеного для обробки, зберігання та передачі даних  і який забезпечує:`,
         "\u2022	доступ до необхідної інформації з будь-якого робочого місця",
         "\u2022	високошвидкісну багаторівневу комутацію",
         "\u2022	доступ до локальних мережевих пристроїв (принтери, сканери і т.д.)",
         "\u2022	безпечний доступ до мережі Інтернет.",
         `Для підприємств, чиї філії, відділені офіси, склади знаходяться в різних місцях міста чи країни ефективним рішенням буде побудова територіально-розподіленої корпоративної мережі.
         Створення такої системи зв’язку між віддаленими підрозділами необхідне для швидкого, надійного і економічного обміну інформацією.`,
          `Для реалізації цього завдання STC пропонує рішення по об’єднанню віддалених один від одного офісів в єдину захищену корпоративну мережу з необхідним набором IT-сервісів.`,
          `Що ви отримаєте в результаті такого об’єднання:`,
          "\u2022	 централізований безпечний доступ до документів, баз даних та інших інформаційних ресурсів підприємства, незалежно від того, в якій філії знаходяться співробітники",
          "\u2022	 істотне підвищення ефективності бізнес-процесів підприємства",
          "\u2022	 єдину політику в питаннях управління, захисту та реалізації доступу до інформаційних ресурсів",
          "\u2022	 полегшення обслуговування і управління IT-інфраструктурою підприємства.",
          `Кваліфікація і досвід наших фахівців дозволяють створювати локальні обчислювальні мережі і територіально-розподілені корпоративні мережі підприємств «під ключ»: від проектування
          до промислової експлуатації, а також здійснювати її подальше адміністрування.`,
          " ↓↓↓ ",
          `Щоб замовити безкоштовну консультацію зі створення локальних мереж або об’єднанню віддалених філій (офісів) в єдину корпоративну мережу, зателефонуйте  або напишіть нам`],
      },
      supportConsulting: {
        title: "Системи моніторингу ІТ",
        description: [`У разі розвитку підприємства, збільшення числа співробітників і впровадження нових додатків і служб моніторинг IT-інфраструктури підприємства стає одним з найважливіших
         завдань забезпечення роботи всього підприємства чи організації. Величезна частина IT-бюджету витрачається на обслуговування існуючої IT-інфраструктури, а не на модернізацію і 
         розвиток самої IT-інфраструктури. Система моніторингу IT-інфраструктури підприємства дозволяє кардинально зменшити витрати на обслуговування IT, автоматично виконувати рутинні
          дії IT-фахівців, попереджати про можливі інциденти, допомагає швидко виявити причину їх появи. Хороша система моніторингу – це не тільки зниження вартості обслуговування 
          IT-інфраструктури підприємства, а і  можливість оперативного контролю за обладнанням та IT-сервісами, зниження ризиків суттєвих збоїв у роботі IT-інфраструктури підприємства 
          в цілому та щодо окремого важливого обладнання. Сучасна система моніторингу заснована на безперервному зборі даних в точках контролю IT-інфраструктури, незалежна від постачальників 
          обладнання, гнучка й масштабована і  забезпечує постійне спостереження за елементами IT-інфраструктури, реєстрацію змін їх станів, а також аналіз продуктивності цих елементів і мережі
           в реальному часі в автономному режимі. Фахівці з обслуговування IT-інфраструктури отримують оперативну інформацію про її стан на всіх рівнях.`,
           " ↓↓↓ ",
           "Щоб замовити безкоштовну консультацію зателефонуйте  або напишіть нам "],
      },
      securityServices: {
        title: "Послуги охорони",
        description: [`Служба охорони STC має державну ліцензію на охоронну діяльність та здійсненює: фізичну охорону, 
          пультову охорону, монтаж та підтримку охоронного обладнання, монтаж та підтримку відеоспостереження, 
          монтаж та підтримку систем контролю доступу. STC має власний Пульт Централізованої Охорони, патрульні автомобілі,
           а також кваліфікований персонал технічних фахівців і штат надійних і перевірених охоронців.
            STC надає охоронні послуги як фізичним особам (охоронна сигналізація, відеоспостереження і фізична охорона приватних будинків і квартир),
             так і різним підприємствам і комерційним структурам (технічна охорона, відеоспостереження, патрулювання, фізична охорона і супровід вантажів).
              У своїй роботі STC застосовує кращі напрацювання великих міжнародних компаній, що працюють в сфері охоронного бізнесу.
               Вся наша діяльність базується на європейському підході до якості. Наші послуги повністю відповідають сучасним вимогам і стандартам,
                основою яких є не тільки висока якість, але і професійна етика, заснована на дотриманні принципу конфіденційності. Всі об'єкти,
                 що охороняються STC, приймаються під матеріальну відповідальність. Для цього STC на постійній основі співпрацює з різними страховими компаніями.
                  Дана схема була розроблена для задоволення головної вимоги наших клієнтів - потреба в безпеці і спокої.
                   Своїм клієнтам ми пропонуємо комплексні рішення в області безпеки: від проектування охоронних відеосистем, 
                   до надання послуг технічної охорони та охорони із залученням охоронців STC.`,
                   " ↓↓↓ ",
                   `Щоб замовити безкоштовну консультацію зателефонуйте  або напишіть нам`],
      },
    },
    contacts:ua.contacts
  },
};
