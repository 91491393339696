import React from "react";
import classNamesFunction from "classnames";
import { NavLink } from "react-router-dom";
import InfoBlockBase from "../InfoBlockBase";
import styles from "./styles.module.css";
import { renderArrayContent } from "../../utils";

const getClassNames = (props) => {
  const { className } = props;
  return classNamesFunction(styles.infoBlock, className);
};

function InfoBlock(props) {
  const {
    title = "Cloud solution",
    description = "I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me.",
    linkLabel = "read more",
    linkPath = "/",
  } = props;

  return (
    <InfoBlockBase title={title} titleClassName={styles.title} className={getClassNames(props)}>
      <div className={styles.description}>
        {renderArrayContent(description)} 
      </div>
      <NavLink className={styles.link} to={linkPath}>
        {linkLabel}
      </NavLink>
    </InfoBlockBase>
  );
}

export default InfoBlock;
