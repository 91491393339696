import { useEffect } from "react";
import { useLocation } from "react-router";
import { scroller } from "react-scroll";
import { contactsHash, partnersHash, solutionsHash } from "../../../../router";

const scrollerConfig = {
  duration: 700,
  delay: 100,
  smooth: true,
};

const useScrollLogic = () => {
  const location = useLocation();
  const locationHash = location.hash;

  useEffect(() => {
    if (locationHash === solutionsHash) {
      scroller.scrollTo(solutionsHash, scrollerConfig);
    }
    if (locationHash === partnersHash) {
      scroller.scrollTo(partnersHash, scrollerConfig);
    }
    if (locationHash === contactsHash) {
      scroller.scrollTo(contactsHash, scrollerConfig);
    }
  }, [locationHash]);
};

export default useScrollLogic;
