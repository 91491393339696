import React from "react";
import classNamesFunction from "classnames";
import styles from "./styles.module.css";

const getClassNames = (props) => {
  const { className, mainColor = "grey" } = props;
  return classNamesFunction(
    {
      [styles.infoBlockGrey]: mainColor === "grey",
      [styles.infoBlockWhite]: mainColor === "white",
    },
    [styles.infoBlock, className]
  );
};

function InfoBlockBase(props) {
  const { title = "Cloud solution", titleClassName, contentClassName, children = null } = props;

  return (
    <div className={getClassNames(props)}>
      <div className={styles.border} />
      <div className={classNamesFunction(styles.content, contentClassName)}>
        <h3 className={classNamesFunction(styles.title, titleClassName)}>{title}</h3>
        {children}
      </div>
    </div>
  );
}

export default InfoBlockBase;
