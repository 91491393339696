import React from "react";
import { NavLink } from "react-router-dom";
import ViewLayout from "../../components/ViewLayout";
import InfoBlockBase from "../../components/InfoBlockBase";
import { useOurServicesLogic } from "./hooks/useOurServicesLogic";

import styles from "./styles.module.css";
import ContactsSection from "./../../components/ContactsSection"
import { ROUTES, solutionsHash } from "../../router";
import useConfig from "./hooks/useConfig";
import { renderArrayContent } from "../../utils";

const { HOME } = ROUTES;

function OurServices() {
  const config = useConfig();
  const { serviceItem } = useOurServicesLogic();    
  
  return (
    <ViewLayout childrenWrapperClassName={styles.view}>
      <div className={styles.bg} />
      <div className={styles.content}>
        <InfoBlockBase
          className={styles.infoBlock}
          titleClassName={styles.infoBlockTitle}
          contentClassName={styles.infoBlockContent}
          mainColor="white"
          title={config.services[serviceItem].title}
        />
        <div className={styles.descriptionSection}>
          <div className={styles.line} />
          <p className={styles.description}>              
             {renderArrayContent(config.services[serviceItem].description)}           
            <NavLink className={styles.link} to={`${HOME}${solutionsHash}`}>
              {config.backLinkLabel}
            </NavLink>
          </p>
        </div>
      </div>
      
      <ContactsSection contacts={config.contacts}/>
    </ViewLayout>
  );
}

export default OurServices;
