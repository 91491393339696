import React from "react";
import ViewLayout from "../../components/ViewLayout";
import TitleBlock from "../../components/TitleBlock";
import ServicesSection from "./components/ServicesSection";
import PartnersSection from "./components/PartnersSection";
import ContactsSection from "./../../components/ContactsSection"
import { useConfig, useScrollLogic } from "./hooks";

import styles from "./styles.module.css";

function Home() {
  const config = useConfig();
  useScrollLogic();

  return (
    <ViewLayout>
      <TitleBlock className={styles.titleBlockWrapper} labels={config.title} />
      <ServicesSection className={styles.servicesSection} services={config.services} />
      <PartnersSection partners={config.partners} />
      <ContactsSection contacts={config.contacts} />
    </ViewLayout>
  );
}

export default Home;
