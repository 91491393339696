import React from "react";
import { Element } from "react-scroll";
import { Fade } from "react-reveal";
import styles from "./styles.module.css";
import { partnersHash } from "../../../../router";

function PartnersSection(props) {
  const { partners } = props;
  const { title, data } = partners;
  return (
    <Element name={partnersHash}>
      <div className={styles.partnersWrapper}>
        <h2>{title}:</h2>

        <div className={styles.logosContainer}>
          {data.map((el, i) => (
            <Fade key={+i} cascade>
              <div className={styles.item}>
                <figure title={el.title}>
                  <img src={el.logo} width="100%" height="100%" alt={el.label} />
                </figure>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </Element>
  );
}

export default PartnersSection;
