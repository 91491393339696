import LinkButton from "../../Buttons/LinkButton";
import { contactsHash, partnersHash, ROUTES, solutionsHash } from "../../../router";
import styles from "./../styles.module.css";

const { HOME, ABOUT_US } = ROUTES;

export const getButtons = (labels) => [
  { label: labels.home, activeClassName: styles.isActive, path: HOME, component: LinkButton },
  {
    label: labels.aboutUs,
    path: ABOUT_US,
    activeClassName: styles.isActive,
    component: LinkButton,
  },
  {
    label: labels.ourServices,
    path: `${HOME}${solutionsHash}`,
    // onClick: () => scroll.scrollTo(740),
    component: LinkButton,
  },
  {
    label: labels.partners,
    // onClick: () => scroll.scrollTo(1500),
    path: `${HOME}${partnersHash}`,
    component: LinkButton,
  },
  {
    label: labels.contacts,
    // onClick: () => scroll.scrollTo(1500),
    path: `${HOME}${contactsHash}`,
    component: LinkButton,
  },
];
