import React from "react";
import Navigation from "../Navigation/index";
import logo from "./../../img/all/1.png";
import { getButtons } from "./utils";

import styles from "./styles.module.css";
import { useConfig } from "./hooks";

function Header() {
  const config = useConfig();

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.logoContainer}>
        <div className={styles.logo}>
          <img src={logo} width="100%" height="100%" alt="logo" />
        </div>
        <div className={styles.textContainer}>
          <div> SECURITY </div>
          <div>technology center</div>
        </div>
      </div>
      <Navigation buttons={getButtons(config.nav)} />
    </div>
  );
}

export default Header;
