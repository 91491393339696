import React from "react";
import { useMobileNavLogic } from "./hooks/useMobileNavLogic";
import { toggleBurgerHandler } from "./utils";
import { useLanguagesConsumer } from "../../languagesContext";

import styles from "./styles.module.css";

function MobileNav(props) {
  const { openMenu, setOpenMenu } = useMobileNavLogic();
  const { currentLanguageKey, settings } = useLanguagesConsumer();
  const { buttons } = props;
  const {
    // Menu list wrapper
    mobileMenuWrapper,
    menuOpen,
    menuClose,
    // Burger button
    burgerContainer,
    openLine1,
    openLine2,
    openLine3,
  } = styles;

  const renderLanguageButton = (languageKey, idx) => {
    const keyToDisplay = settings.languagesOptions[languageKey].key;
    return (
      <button
        className={styles.languageBtn}
        key={keyToDisplay + idx}
        onClick={() => settings.setCurrentLanguageKey(languageKey)}
      >
        <abbr className={styles.langAbbr} title={settings.languagesOptions[languageKey].title}>
          {currentLanguageKey === languageKey ? <b>{keyToDisplay}</b> : keyToDisplay}
        </abbr>
      </button>
    );
  };

  return (
    <>
      {openMenu && <div className={styles.backdrop} />}
      <div className={mobileMenuWrapper}>
        <div className={burgerContainer} onClick={() => toggleBurgerHandler(openMenu, setOpenMenu)}>
          <div className={openMenu ? openLine1 : null} />
          <div className={openMenu ? openLine2 : null} />
          <div className={openMenu ? openLine3 : null} />
        </div>

        <div className={`${styles.ulWrapper} ${openMenu ? menuOpen : menuClose}`}>
          <ul>
            {buttons.map((button, i) => {
              const { component: Button, onClick = () => {}, ...rest } = button;
              const handleClick = () => {
                setOpenMenu(false);
                onClick();
              };
              return (
                <li key={+i}>
                  <Button {...rest} onClick={handleClick} />
                </li>
              );
            })}
          </ul>
          <div className={styles.languagesWrapper}>
            <div className={styles.line} />
            {Object.keys(settings.languagesOptions).map(renderLanguageButton)}
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileNav;
