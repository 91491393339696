export const homeLabels = {
  en: {
    title: {
      title: "Security technology center",
      description: `I'm a paragraph. Click here to add your own text and edit me. It’s easy.
         Just click “Edit Text” or double click me.`,
    },
    services: {
      title: "Services",
      ditaileLinkLabel: "+ read more ...",
    },
    partners: {
      title: "Partners",
      iconTitles: {
        apc: "apc",
        check_point: "check_point",
        elk: "elk",
        freebsd: "freebsd",
        hetzner: "hetzner",
        intel: "intel",
        kerio: "kerio",
        linux: "linux",
        mcafee: "mcafee",
        microsoft: "microsoft",
        mikrotik: "mikrotik",
        openVPN: "openVPN",
        pfSense: "pfSense",
        vmware: "vmware",
      },
    },
    contacts: {
      title: "Our contacts",
      data: [
        {
          label: "Legal name",
          value: "LLC «Security Technology Center»",
        },
        {
          label: "EDRPOU code",
          value: "3718361",
        },
        {
          label: "address",
          value:
            "Україна, 76019, Івано-Франківська обл., місто Івано-Франківськ, вул. Кобилянської, 36А/14",
        },
        {
          label: "email",
          value: "stcenter.if@gmail.com",
        },
        { label: "web-site", value: "www.stc-if.com.ua" },
        {
          label: "phone",
          value: "+38(050) 433-88-33",
        },
        {
          label: "phone",
          value: "+38(093) 015-00-73",
        },
      ],
    },
  },
  ua: {
    title: {
      title: "Центр технологій безпеки",
      description: `Надаємо послуги у сфері інформаційної та кібербезпеки, 
        проектування мережевої інфраструктури і розробляємо індивідуальні рішення з урахуванням потреб Вашого бізнесу. 
        Ми допоможемо Вам створити систему управління інформаційною безпекою вашої компанії і завдяки поєднанню професіоналізму
         і досвіду роботи забезпечимо високоякісну технічну підтримку ІТ інсфраструктури Вашого підприємства.`,
    },
    services: {
      title: "Рішення",
      ditaileLinkLabel: "+ Детальніше ...",
    },
    partners: {
      title: "Партнери",
      iconTitles: {
        apc: "apc",
        check_point: "check_point",
        elk: "elk",
        freebsd: "freebsd",
        hetzner: "hetzner",
        intel: "intel",
        kerio: "kerio",
        linux: "linux",
        mcafee: "mcafee",
        microsoft: "microsoft",
        mikrotik: "mikrotik",
        openVPN: "openVPN",
        pfSense: "pfSense",
        vmware: "vmware",
      },
    },
    contacts: {
      title: "Наші контакти",
      data: [
        {
          label: "Юридична назва",
          value: "ТОВ  «Центр технологій безпеки»",
        },
        {
          label: "код ЄДРПОУ",
          value: "3718361",
        },
        {
          label: "адреса",
          value:
            "Україна, 76019, Івано-Франківська обл., місто Івано-Франківськ, вул. Кобилянської, 36А/14",
        },
        {
          label: "email",
          value: "stcenter.if@gmail.com",
        },
        { label: "веб-сайт", value: "www.stc-if.com.ua" },
        {
          label: "тел",
          value: "+38(050) 433-88-33",
        },
        {
          label: "тел",
          value: "+38(093) 015-00-73",
        },
      ],
    },
  },
};
