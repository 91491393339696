import React from "react";
import classNamesFunction from "classnames";
import Fade from "react-reveal/Fade";
import InfoBlock from "./../InfoBlock/InfoBlock";
import styles from "./styles.module.css";

const getClassNames = (props) => {
  const { className } = props;
  return classNamesFunction(styles.infoBlocksList, className);
};

function InfoBlocksList(props) {
  const { items } = props;

  const groupedItems = groupArray(items, 3); // 3 items per row;
  const renderItem = (item, idx) => (
    <InfoBlock key={item.title + idx} className={styles.item} {...item} />
  );
  const renderRow = (rowItems, idx) => (
    <div key={idx} className={styles.row}>
      {rowItems.map(renderItem)}
    </div>
  );

  return (
    <Fade cascade>
      <div className={getClassNames(props)}>{groupedItems.map(renderRow)}</div>
    </Fade>
  );
}

function groupArray(arr = [], chunkLength) {
  return arr.reduce((acc, val) => {
    if (acc.length === 0) {
      acc.push([val]);
      return acc;
    }
    if (acc[acc.length - 1].length < chunkLength) {
      acc[acc.length - 1].push(val);
    } else {
      acc.push([val]);
    }

    return acc;
  }, []);
}

export default InfoBlocksList;
