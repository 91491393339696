export const aboutUsLabels = {
  en: {
    title: "Why Choose ITG. Consulting?",
    blocks: [
      {
        title: "011.",
        description: [`I'm a paragraph. Click here to add your own text and edit me. It’s easy.
         Just click “Edit Text” or double click me to add your own content and make changes to the font. `],
      },
      {
        title: "022.",
        description: [`I'm a paragraph. Click here to add your own text and edit me. It’s easy.
         Just click “Edit Text” or double click me to add your own content and make changes to the font.`],
      },
      {
        title: "033.",
        description:[ `I'm a paragraph. Click here to add your own text and edit me. It’s easy.
         Just click “Edit Text” or double click me to add your own content and make changes to the font..`],
      },
    ],
  },
  ua: {
    title: "Чому обирають Центр технологій безпеки?",
    blocks: [   
      {
        title: `НАШІ КОМПЕТЕНЦІЇ.`,
        description: [
          "\u2022 Проектування і впровадження рішень захисту корпоративних мереж та інфраструктури ЦОД." ,
          "\u2022 Технічний супровід ІТ проектів із забезпеченням інформаційної безпеки.",
          "\u2022 Постачання мережевого обладнання.",
          "\u2022 Впровадження рішень віддаленого доступу (робота віддалених (мобільних) офісів та філій).",
          "\u2022 Консалтинг у сфері інформаційної безпеки.",
          "\u2022 Охоронні послуги."
        ],
      },
      {
        title: "ВИКЛИКИ ЧАСУ.",
        description: [`В сьогоднішньому світі інформація — найважливіший актив будь-якої компанії,
         для якої  забезпечення інформаційної безпеки є завжди важливим і актуальним завданням.
          Ми допоможемо Вам на всіх етапах створення системи управління інформаційною безпекою Вашої організації.`],
      },
      {
        title: "ЧОМУ ЦЕ ВАЖЛИВО.",
        description: [`Дані про партнерів, покупців і постачальників, управлінські та бухгалтерські документи,
         ділове листування та спілкування, персональні дані співробітників, інформація про технології та ноу-хау,
          про автоматизовані технологічні процеси потребують збереження і захисту від стороннього втручання.
           Адже з кожним роком зростає кількість мережевих атак зловмисників, виникають загрози порушення конфіденційності інформації всередині компанії,
            що призводить до фінансових втрат, і часто – дуже значних.`],
      },
      {
        title: "ЯК ЗАХИЩАТИ ІНФОРМАЦІЮ.",
        description: [`Розробка і створення системи управління інформаційною безпекою є важливим  і надзвичайно актуальним питанням для кожної компанії,
         яка дбає про свій захист. Центр технологій безпеки допоможе Вам створити таку систему,
          яка є сукупністю організаційних та інженерно-технічних заходів, що спрямовані на забезпечення захисту інформації від розголошення,
          витоку і несанкціонованого доступу.`],
      },      
    ],
  },
};
